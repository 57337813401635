import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import useMarvelServices from "../../services/MarvelServices";
import ErrorMassage from "../errorMassage/ErrorMassage";
import Spinner from "../spinner/Spinner";

import "./charList.scss";

const CharList = (props) => {
  const [charList, setCharList] = useState([]);
  const [newItemLoading, setNewItemLoading] = useState(false);
  const [offset, setOffset] = useState(210);
  const [charEnded, setCharEnded] = useState(false);

  const { loading, error, getAllCharacters } = useMarvelServices();

  
  // здесь мы вызываем onRequest первый раз и он рендерит первые 9 персонажей (charList) используя offset
  // если второй аргумент пустой [] то такая функция выполниться только один раз
  useEffect(() => {
    onRequest(offset, true);
    // eslint-disable-next-line
  }, []);
  
  
  // условие проверяет первичную или дальнейшую загрузку елементов через кнопку (initial)
  // onRequest отпарвляет запрос getAllCharacters с басовым offset
  // ответ обрабатывается через onCharListLoaded
  const onRequest = (offset, initial) => {
    initial ? setNewItemLoading(false) : setNewItemLoading(true);
    getAllCharacters(offset).then(onCharListLoaded);
  };

  // newCharList - это ответ от сервера полученый через getAllCharacters
  // onCharListLoaded - это метод проверки ко-ва персонажей полученных от сервера 
  // и изменения state при допзагрузке персонажей
  const onCharListLoaded = (newCharList) => {
    let ended = false;
    if (newCharList.length < 9) {
      ended = true;
    }
    // мы создаем переменную ended, которая переходит в setCharEnded

    // меняем state после получения ответа от сервера (объект newCharList) 
    // В хук приходит предыдущее значение state и через collback возвращается новое значение
    setCharList((charList) => [...charList, ...newCharList]);
    setNewItemLoading((newItemLoading) => false);
    setOffset((offset) => offset + 9);
    setCharEnded((charEnded) => ended);
  };

  const itemRefs = useRef([]);

  const focusOnItem = (id) => {
    itemRefs.current.forEach((item) =>
      item.classList.remove("char__item_selected")
    );
    itemRefs.current[id].classList.add("char__item_selected");
    itemRefs.current[id].focus();
  };

  // Этот метод создан для оптимизации, что б не помещать такую конструкцию в return
  // renderItems принимает в себя массив item из 9 элементов согласно запроса на сервер
  function renderItems(arr) {
    const items = arr.map((item, i) => {
      let imgStyle = { objectFit: "cover" };
      if (
        item.thumbnail ===
        "http://i.annihil.us/u/prod/marvel/i/mg/b/40/image_not_available.jpg"
      ) {
        imgStyle = { objectFit: "unset" };
      }
// первый return возвращает li в виде items
      return (
        <CSSTransition key={item.id} timeout={500} classNames="char__item">
          <li
            className="char__item"
            tabIndex={0}
            ref={(el) => (itemRefs.current[i] = el)}
            key={item.id}
            onClick={() => {
              props.onCharSelected(item.id);
              focusOnItem(i);
            }}
            // при клике ми получаем id item-a используя метод,
            // который передался в этот компонент в виде props из App
            onKeyPress={(e) => {
              if (e.key === " " || e.key === "Enter") {
                props.onCharSelected(item.id);
                focusOnItem(i);
              }
            }}
          >
            <img src={item.thumbnail} alt={item.name} style={imgStyle} />
            <div className="char__name">{item.name}</div>
          </li>
        </CSSTransition>
      );
    });
    // второй return возвращает ul с items
    return (
      <ul className="char__grid">
        <TransitionGroup component={null}>{items}</TransitionGroup>
      </ul>
    );
  }

  const items = renderItems(charList);
  // renderItems возвращает нам 9 item из полученного массива от сервера
  // список 9 персонажей записываем в переменную item!
  

  const errorMessage = error ? <ErrorMassage /> : null;
  const spinner = loading && !newItemLoading ? <Spinner /> : null;
  // спинер будет при загрузке, но не в случае загрузки новых персонажей

  // финальный рендеринг компонента с учетом всей логики
  return (
    <div className="char__list">
      {errorMessage}
      {spinner}
      {items}
      <button
        className="button button__main button__long"
        disabled={newItemLoading}
        style={{ display: charEnded ? "none" : "block" }}
        onClick={() => onRequest(offset)}
      >
        <div className="inner">load more</div>
      </button>
    </div>
  );
};

CharList.propTypes = {
  onCharSelected: PropTypes.func.isRequired,
};

export default CharList;
