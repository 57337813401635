import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AppHeader from "../appHeader/AppHeader";
import MainPage from "../pages/MainPage";
import ComicsPage from "../pages/ComicsPage";
import SingleComicPage from "../pages/SingleComicPage";
import SingleCharPage from "../pages/SingleCharPage";
import Spinner from "../spinner/Spinner";

const Page404 = lazy(() => import("../pages/404"));
// подгрузка этого компонента будет происходить тогда, когда он повляется на страничке
// Suspence отрабатывает ошибку загрузки компонента который грузиться через динамический импорт
// динамический импорт всегда помещается после статического


// Router - это маршрутизатор, который оборачивает всю структуру и отвечает за всю маршрутизацию ссылок
// Route - маршрут который будет грузться если в адресной строке появится определеная ссылка (path)
const App = () => {


  return ( 
    <Router>
      <div className="app">
        <AppHeader />
        <main>
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/comics" element={<ComicsPage />} />
              <Route path="/comics/:comicId" element={<SingleComicPage/>} />
              <Route path="/char/:charId" element={<SingleCharPage />} />
              <Route path="*" element={<Page404 />} />
             </Routes>
           </Suspense>
         </main>
      </div>
   </Router>
   

  );
};

export default App;
